import { FC } from 'react'
import cn from 'classnames'
import { McIcon, MirIcon, VisaIcon } from 'src/shared/assets/icons'
import { CardVariant } from 'src/shared/constants/cardTypes'
import styles from './CardPaySystemIcon.module.scss'

export const CardPaySystemIcon: FC<{
  cardType: CardVariant
  className?: string
}> = ({ cardType, className }) => {
  return {
    [CardVariant.Visa]: <VisaIcon className={cn(styles.cardLogo, className)} />,
    [CardVariant.MC]: <McIcon className={cn(styles.cardLogo, className)} />,
    [CardVariant.Mir]: <MirIcon className={cn(styles.cardLogo, className)} />,
  }[cardType]
}
