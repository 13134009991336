import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { Button, CardPaySystemIcon } from 'src/shared/ui'
import { useNavigate } from 'react-router-dom'
import axios, { AxiosError } from 'axios'
// local libs
import { bankCardResolver } from '../resolver'
import { Checkbox } from 'src/shared/ui/Checkbox'
import {
  CardCodeInput,
  CardExpirationDateInput,
  CardHolderInput,
  CardNumberInput,
  EmailInput,
} from 'src/shared/ui/Input'
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { store } from 'src/app/store'
import { api, userSession } from 'src/app/config/api'
import { parseCardType } from 'src/shared/utils'
import { CARD_METHODS } from 'src/shared/constants/cardMethods'
// types
import { BankCardFormEntities, BankCardFromData } from '../types'
import { ResponseStatusEnum } from 'src/shared/types/enums'

import styles from '../BankCardMethod.module.scss'

export const RegularForm: FC = () => {
  const navigate = useNavigate()
  const { translate } = useTranslate()

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<BankCardFromData>({
    resolver: bankCardResolver,
  })

  const { cardNumber } = watch()

  const onSubmit = (formVals: BankCardFromData) =>
    axios
      .request<
        ResponseWithData<{
          amount: number
          currency: string
          transactionId: string
        }>
      >({
        url: api.deposit,
        method: 'POST',
        data: {
          cardInfo: {
            pan: formVals[BankCardFormEntities.cardNumber],
            cardholder: formVals[BankCardFormEntities.cardHolderName],
            expiration: formVals[BankCardFormEntities.expDate],
            cvv: formVals[BankCardFormEntities.cvc],
            remember: formVals[BankCardFormEntities.saveData] || false,
          },
          paymentMethod: CARD_METHODS.bankcard,
          hash: userSession,
        },
      })
      .then((res) => {
        if (res.data.data && res.data.type === ResponseStatusEnum.DATA) {
          return navigate('/payment-success')
        }
        navigate('/contact-store')
      })
      .catch((err: AxiosError<ResponseMessage>) => {
        console.error('Deposit error:', err)
        navigate('/contact-store')
        return Promise.reject()
      })

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.bankCardMethodContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.row}>
        <CardNumberInput
          id={BankCardFormEntities.cardNumber}
          control={control}
          error={errors[BankCardFormEntities.cardNumber]?.message}
          label={translate('Bank Card Method.fieldCardNumberLabel')}
          placeholder={translate('Bank Card Method.fieldCardNumberPlaceholder')}
        />
        {cardNumber?.length > 1 && (
          <CardPaySystemIcon
            cardType={parseCardType(cardNumber)}
            className={styles.cardIcon}
          />
        )}
      </div>
      <div className={styles.row}>
        <CardExpirationDateInput
          id={BankCardFormEntities.expDate}
          control={control}
          error={errors[BankCardFormEntities.expDate]?.message}
          label={translate('Bank Card Method.fieldExpiryDateLabel')}
          placeholder={translate('Bank Card Method.fieldExpiryDatePlaceholder')}
        />
        <CardCodeInput
          id={BankCardFormEntities.cvc}
          control={control}
          error={errors[BankCardFormEntities.cvc]?.message}
          label={translate('Bank Card Method.fieldCVCLabel')}
          placeholder={translate('Bank Card Method.fieldCVCPlaceholder')}
        />
      </div>
      <div className={styles.row}>
        <CardHolderInput
          id={BankCardFormEntities.cardHolderName}
          control={control}
          error={errors[BankCardFormEntities.cardHolderName]?.message}
          label={translate('Bank Card Method.fieldCardHolderLabel')}
          placeholder={translate('Bank Card Method.fieldCardHolderPlaceholder')}
        />
      </div>
      <div className={styles.row}>
        <EmailInput
          id={BankCardFormEntities.email}
          control={control}
          error={errors[BankCardFormEntities.email]?.message}
          label={translate('Payment Method Common.fieldEmailLabel')}
          placeholder={translate('Payment Method Common.fieldEmailPlaceholder')}
        />
      </div>
      <div className={styles.row}></div>
      <div className={styles.row}>
        <Checkbox
          id={BankCardFormEntities.saveData}
          control={control}
          label={translate('Payment Method Common.checkboxSaveDataLabel')}
        />
      </div>
      <div className={styles.row}></div>
      <Button size="stretch" disabled={isSubmitting}>
        {translate('Bank Card Method.submitButton', {
          amount: `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`,
        })}
      </Button>
    </form>
  )
}
