import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { phoneValidationSchema } from 'src/shared/ui/Input/specificInputs/PhoneInput'
import { smsCodeValidationSchema } from 'src/shared/ui/Input/specificInputs/SmsCodeInput'
import {
  MobileCommerceEntities,
  MobileCommerceFromDataPhoneStep,
  MobileCommerceFromDataSmsCodeStep,
} from './types'

export const mobileCommercePhoneResolver =
  yupResolver<MobileCommerceFromDataPhoneStep>(
    yup.object().shape({
      [MobileCommerceEntities.phone]: phoneValidationSchema,
    }),
  )

export const mobileCommerceSmsCodeResolver =
  yupResolver<MobileCommerceFromDataSmsCodeStep>(
    yup.object().shape({
      [MobileCommerceEntities.smsCode]: smsCodeValidationSchema,
    }),
  )
