import { FC } from 'react'
import { useTranslate } from 'src/shared/hooks/useTranslate'
// local libs
import { Input } from '../..'

export type PhonInputProps = {
  id: string
  label: string
  placeholder: string
  error: string | undefined
  control: unknown
}

export const PhoneInput: FC<PhonInputProps> = ({
  id,
  label,
  placeholder,
  control,
  error,
}) => {
  const { translate } = useTranslate()

  return (
    <Input
      name={id}
      id={id}
      label={label}
      control={control}
      autoComplete="email"
      error={translate(error)}
      placeholder={placeholder}
      replacement={{ _: /\d/ }}
      mask={'+7 (___) ___-__-__'}
    />
  )
}
