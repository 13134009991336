import { FC } from 'react'

export const CheckboxCheckedIcon: FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect width="20" height="20" strokeWidth="4" />
      <path d="M8.83333 11.1667L7.04167 9.375C6.88889 9.22222 6.69444 9.14583 6.45833 9.14583C6.22222 9.14583 6.02778 9.22222 5.875 9.375C5.72222 9.52778 5.64583 9.72222 5.64583 9.95833C5.64583 10.1944 5.72222 10.3889 5.875 10.5417L8.25 12.9167C8.41667 13.0833 8.61111 13.1667 8.83333 13.1667C9.05556 13.1667 9.25 13.0833 9.41667 12.9167L14.125 8.20833C14.2778 8.05556 14.3542 7.86111 14.3542 7.625C14.3542 7.38889 14.2778 7.19444 14.125 7.04167C13.9722 6.88889 13.7778 6.8125 13.5417 6.8125C13.3056 6.8125 13.1111 6.88889 12.9583 7.04167L8.83333 11.1667ZM4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667Z" />
    </svg>
  )
}
