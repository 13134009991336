export enum MobileCommerceEntities {
  phone = 'phone',
  smsCode = 'smsCode',
}

export type MobileCommerceFromDataPhoneStep = {
  [MobileCommerceEntities.phone]: string
}

export type MobileCommerceFromDataSmsCodeStep = {
  [MobileCommerceEntities.smsCode]: string
}
