import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { phoneValidationSchema } from 'src/shared/ui/Input/specificInputs/PhoneInput'
import { SberPayEntities, SberPayFromData } from './types'

export const sberPayResolver = yupResolver<SberPayFromData>(
  yup.object().shape({
    [SberPayEntities.phone]: phoneValidationSchema,
  }),
)
