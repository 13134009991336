import { useState, useEffect } from 'react'
import axios, { AxiosError } from 'axios'
// local libs
import { api, userSession } from 'src/app/config/api'
import { useResponseHandler } from '../useResponseHandler'
import { CardsResponse } from './types'
import { StoredCard } from 'src/shared/types/cards'

export function useSaveCardsRequest() {
  const { handleResponse } = useResponseHandler()
  const [cards, setCards] = useState<Array<StoredCard>>([])
  const [key, setKey] = useState(Math.random())

  useEffect(() => {
    const controller = new AbortController()
    axios
      .get<CardsResponse>(api.cards, {
        signal: controller.signal,
        params: { hash: userSession },
      })
      .then((res) => {
        const resData = res.data
        if (resData) {
          handleResponse(resData)
          setCards(resData.data)
        }
      })
      .catch((err: AxiosError<ResponseMessage>) => {
        const errData = err.response?.data
        if (errData) handleResponse(errData)
      })

    return () => controller.abort()
  }, [key])

  return {
    cards,
    refresh: () => setKey(Math.random()),
  }
}
