import dayjs from 'dayjs'
import * as yup from 'yup'
import {
  validationExpiredKey,
  validationInvalidFormatKey,
  validationRequiredKey,
  validationExceedKey,
} from 'src/shared/constants'

export const delimiter = '/'

export const cardExpirationDateValidationSchema = yup
  .string()
  .required(validationRequiredKey)
  .test({
    message: validationInvalidFormatKey,
    test: function (value) {
      const date = dayjs(value, `MM${delimiter}YY`, true)
      return date.isValid()
    },
  })
  .test({
    message: validationExceedKey,
    test: function (value) {
      const date = dayjs(value, `MM${delimiter}YY`, true)
      const currentDate = dayjs().startOf('month')
      return date.isBefore(currentDate.add(20, 'year'))
    },
  })
  .test({
    message: validationExpiredKey,
    test: function (value) {
      const date = dayjs(value, `MM${delimiter}YY`, true)
      const currentDate = dayjs().startOf('month')
      const diff = currentDate.diff(date)
      return diff <= 0
    },
  })
