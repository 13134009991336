import cn from 'classnames'
import { FC } from 'react'
// local libs
import { Text, TextKind, TextSizes } from '../Text'
import styles from './Button.module.scss'
// types
import { ButtonProps } from './types'

export const Button: FC<ButtonProps> = ({
  disabled,
  children,
  kind = 'primary',
  size,
  icon,
  className,
  ...rest
}) => {
  return (
    <button
      className={cn(styles.button, className, styles[kind], {
        [styles.longButton]: icon || (size && size === 'long'),
        [styles.stretchButton]: size && size === 'stretch',
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      {...rest}
    >
      {icon && <div className={styles.iconWrapper}>{icon}</div>}
      <Text kind={TextKind.title} size={TextSizes.small}>
        {children}
      </Text>
    </button>
  )
}
