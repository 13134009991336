export enum CardVariant {
  Visa = 'Visa',
  MC = 'MC',
  Mir = 'Mir',
  // UnionPay = 'UnionPay',
}
export const CARD_TYPES_MAP = {
  '4': CardVariant.Visa,
  '5': CardVariant.MC,
  '22': CardVariant.Mir,
  // '6': CardVariant.UnionPay
} as Record<string, CardVariant>
