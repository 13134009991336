import { FC, useState } from 'react'
// local libs
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { Button, CopyButton, PaymentMethodCard, Text } from 'src/shared/ui'
import { store } from 'src/app/store'
import { TextKind, TextSizes } from 'src/shared/ui/Text'
import styles from './SBPMethod.module.scss'
// types
import {
  MethodNameEnum,
  PaymentMethodProps,
} from 'src/app/Layout/PaymentMethods/types'

export const SBPMethod: FC<PaymentMethodProps> = ({ disabled }) => {
  const { translate } = useTranslate()
  const [currStep, setCurrStep] = useState<'start' | 'transferred'>('start')

  const phone = store.paymentData.value?.paymentInfo.phone || ''
  const amount = `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`

  const currStepDisplay = {
    start: (
      <Button
        size="stretch"
        onClick={() => {
          store.isPaymentMethodsDisabled.value = true
          setCurrStep('transferred')
        }}
      >
        {translate('SBP Method.startPaymentButtonText', {
          amount,
        })}
      </Button>
    ),
    transferred: (
      <div className={styles.container}>
        <section>
          <Text
            kind={TextKind.title}
            size={TextSizes.small}
            className={styles.title}
          >
            {translate('SBP Method.copyNumberStepTitle')}
          </Text>
          <CopyButton
            size="stretch"
            text={translate('SBP Method.copyNumberButtonText')}
            copyText={phone}
          />
          <Text
            kind={TextKind.label}
            size={TextSizes.small}
            className={styles.btnCaption}
          >
            {translate('SBP Method.copyNumberCaption')}
          </Text>
        </section>

        <section>
          <Text
            kind={TextKind.title}
            size={TextSizes.small}
            className={styles.title}
          >
            {translate('SBP Method.copyAmountStepTitle')}
          </Text>
          <CopyButton
            size="stretch"
            text={translate('SBP Method.copyAmountButtonText')}
            copyText={amount}
          />
          <Text
            kind={TextKind.label}
            size={TextSizes.small}
            className={styles.btnCaption}
          >
            {translate('SBP Method.copyAmountCaption')}
          </Text>
        </section>

        <Button size="stretch">
          {translate('SBP Method.transferredSuccessButtonText', {
            amount,
          })}
        </Button>
      </div>
    ),
  }

  return (
    <PaymentMethodCard
      id={MethodNameEnum.SBP}
      title={translate('SBP Method.title')}
      caption={translate('SBP Method.caption')}
      iconLink="/sbp.svg"
      iconWrapperColor="var(--paymentMethodCard-sbpMethodColor)"
      disabled={disabled}
    >
      {currStepDisplay[currStep]}
    </PaymentMethodCard>
  )
}
