import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
// local libs
import {
  cardCodeValidationSchema,
  cardExpirationDateValidationSchema,
  cardHolderValidationSchema,
  cardNumberValidationSchema,
  emailValidationSchema,
} from 'src/shared/ui/Input'
// types
import { BankCardFormEntities, BankCardFromData } from './types'

export const bankCardResolver = yupResolver<BankCardFromData>(
  yup.object().shape({
    [BankCardFormEntities.cardNumber]: cardNumberValidationSchema,
    [BankCardFormEntities.expDate]: cardExpirationDateValidationSchema,
    [BankCardFormEntities.cvc]: cardCodeValidationSchema,
    [BankCardFormEntities.cardHolderName]: cardHolderValidationSchema,
    [BankCardFormEntities.email]: emailValidationSchema,
    [BankCardFormEntities.sendReceipt]: yup.boolean(),
    [BankCardFormEntities.saveData]: yup.boolean(),
  }),
)

export const savedCardResolver = yupResolver<
  Pick<BankCardFromData, BankCardFormEntities.cvc>
>(
  yup.object().shape({
    [BankCardFormEntities.cvc]: cardCodeValidationSchema,
  }),
)
