import { FC, useState } from 'react'
// local libs
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { Input } from '../../Input'
import { CrossedEyeIcon, EyeIcon } from 'src/shared/assets/icons'
import styles from './CardExpirationDateInput.module.scss'
// types
import { CardCodeInputProps } from './types'

export const CardCodeInput: FC<CardCodeInputProps> = ({
  id,
  label,
  placeholder,
  control,
  error,
}) => {
  const { translate } = useTranslate()
  const [cvcIsShown, setCvcIsShown] = useState(false)
  const cvcInputIcon = cvcIsShown ? (
    <EyeIcon
      className={styles.visibilityIcon}
      onClick={() => setCvcIsShown(false)}
    />
  ) : (
    <CrossedEyeIcon
      className={styles.visibilityIcon}
      onClick={() => setCvcIsShown(true)}
    />
  )

  return (
    <Input
      name={id}
      id={id}
      control={control}
      error={translate(error)}
      label={label}
      autoComplete="cc-csc"
      placeholder={placeholder}
      mask="___"
      replacement={{ _: /\d/ }}
      icon={cvcInputIcon}
      isInputHidden={!cvcIsShown}
    />
  )
}
