import { FieldValues, Path, UseFormSetError } from 'react-hook-form'
// local libs
import {
  getMessageParams,
  isValidationErrorMessages,
} from './helpers/validationError'
import { useTranslate } from '../useTranslate'
import { unexpectedError } from './assets/fixtures'
// types
import { ResponseStatusEnum } from 'src/shared/types/enums'
import { ValidationErrorMessages } from './types'

export const useResponseHandler = () => {
  const { translate } = useTranslate()

  const getValidationErrorsMessages = (res: ValidationErrorMessages) => {
    console.error(translate(res.localKey))

    const messagesText: Record<string, string> = {}

    // для формата ошибок, который содержит ключи локализатора
    if ('fields' in res) {
      res.fields.forEach(({ field, errors }) => {
        const allErrors: Array<string> = []

        errors.forEach((error) => {
          const params = getMessageParams(error.parameters, translate)
          allErrors.push(translate(error.localKey, params))
        })

        messagesText[field] = allErrors.join(' ')
      })
    }

    // для формата ошибок, который не содержит ключи локализатора
    if ('errors' in res) {
      Object.entries(res.errors).forEach(([key, value]) => {
        // key в формате "сущность.название поля" (прим. "paymentInfo.amount")
        const keyArray = key.split('.')
        const field = keyArray[1] ?? key

        messagesText[field] = value.join(' ')
      })
    }

    return messagesText
  }

  const handleErrorResponse = (
    res: ResponseMessage | ValidationErrorMessages,
    setError?: UseFormSetError<FieldValues>,
  ) => {
    if (isValidationErrorMessages(res) && setError) {
      const messages = getValidationErrorsMessages(res)

      Object.entries(messages).forEach(([key, value]) =>
        setError(key as Path<FieldValues>, {
          message: value,
        }),
      )
    } else {
      const params = getMessageParams(res.parameters || [], translate)
      const message = translate(res.localKey, params)

      console.error(message)
    }
  }

  const handleResponse = (
    res: ResponseMessage | ValidationErrorMessages | undefined,
    setError?: UseFormSetError<FieldValues>,
  ) => {
    // для типа DATA не нужен показ нотификации
    if (res?.type === ResponseStatusEnum.DATA) return

    if (res?.type === ResponseStatusEnum.SUCCESS) {
      // при успехе не должно быть никаких уведомлений
      return
    }

    if (res?.type === ResponseStatusEnum.ERROR) {
      handleErrorResponse(res, setError)

      return
    }

    handleErrorResponse(unexpectedError)
  }

  return { handleResponse, getValidationErrorsMessages }
}
