import { InputHTMLAttributes, FC } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { useMask } from '@react-input/mask'
import cn from 'classnames'
//local
import { ErrorIcon } from 'src/shared/assets/icons'
import { Text } from 'src/shared/ui/Text'
import { TextKind, TextSizes } from 'src/shared/ui/Text/types'
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { InputProps } from './Input.types'
// styles
import styles from './Input.module.scss'

export const Input: FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
  error,
  label,
  id,
  control,
  mask,
  replacement,
  isInputHidden,
  icon,
  ...rest
}) => {
  const inputRef = useMask({ mask, replacement })
  const { translate } = useTranslate()

  return (
    <Controller
      name={id}
      control={control as Control<FieldValues, unknown>}
      render={({ field }) => {
        return (
          <div className={styles.inputContainer}>
            {label && (
              <label className={styles.label}>
                <Text kind={TextKind.label} size={TextSizes.medium}>
                  {label}
                </Text>
              </label>
            )}
            <div className={styles.inputWrapper}>
              <input
                className={cn(styles.input, {
                  [styles.inputError]: Boolean(error),
                  [styles.inputHidden]: isInputHidden,
                })}
                {...rest}
                {...field}
                value={field.value as string | number | ReadonlyArray<string>}
                ref={(node) => {
                  if (typeof field.ref === 'function') field.ref(node)
                  // @ts-expect-error хак для двух рефов
                  else field.ref.current = node

                  if (mask && replacement) inputRef.current = node
                }}
              />
              {icon && (
                <div
                  className={cn(styles.inputIcon, {
                    [styles.inputIconWithError]: error,
                  })}
                >
                  {icon}
                </div>
              )}
              {error && <ErrorIcon className={styles.errorIcon} />}
            </div>
            {error && (
              <div className={styles.errorMsg}>
                <Text kind={TextKind.label} size={TextSizes.medium}>
                  {translate(error)}
                </Text>
              </div>
            )}
          </div>
        )
      }}
    />
  )
}
