import { FC } from 'react'

export const ErrorIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99999 11.3333C8.18888 11.3333 8.34722 11.2694 8.47499 11.1416C8.60277 11.0139 8.66666 10.8555 8.66666 10.6666C8.66666 10.4778 8.60277 10.3194 8.47499 10.1916C8.34722 10.0639 8.18888 9.99998 7.99999 9.99998C7.81111 9.99998 7.65277 10.0639 7.52499 10.1916C7.39722 10.3194 7.33333 10.4778 7.33333 10.6666C7.33333 10.8555 7.39722 11.0139 7.52499 11.1416C7.65277 11.2694 7.81111 11.3333 7.99999 11.3333ZM7.99999 8.66665C8.18888 8.66665 8.34722 8.60276 8.47499 8.47498C8.60277 8.3472 8.66666 8.18887 8.66666 7.99998V5.33331C8.66666 5.14442 8.60277 4.98609 8.47499 4.85831C8.34722 4.73054 8.18888 4.66665 7.99999 4.66665C7.81111 4.66665 7.65277 4.73054 7.52499 4.85831C7.39722 4.98609 7.33333 5.14442 7.33333 5.33331V7.99998C7.33333 8.18887 7.39722 8.3472 7.52499 8.47498C7.65277 8.60276 7.81111 8.66665 7.99999 8.66665ZM7.99999 14.6666C7.07777 14.6666 6.21111 14.4916 5.39999 14.1416C4.58888 13.7916 3.88333 13.3166 3.28333 12.7166C2.68333 12.1166 2.20833 11.4111 1.85833 10.6C1.50833 9.78887 1.33333 8.9222 1.33333 7.99998C1.33333 7.07776 1.50833 6.21109 1.85833 5.39998C2.20833 4.58887 2.68333 3.88331 3.28333 3.28331C3.88333 2.68331 4.58888 2.20831 5.39999 1.85831C6.21111 1.50831 7.07777 1.33331 7.99999 1.33331C8.92222 1.33331 9.78888 1.50831 10.6 1.85831C11.4111 2.20831 12.1167 2.68331 12.7167 3.28331C13.3167 3.88331 13.7917 4.58887 14.1417 5.39998C14.4917 6.21109 14.6667 7.07776 14.6667 7.99998C14.6667 8.9222 14.4917 9.78887 14.1417 10.6C13.7917 11.4111 13.3167 12.1166 12.7167 12.7166C12.1167 13.3166 11.4111 13.7916 10.6 14.1416C9.78888 14.4916 8.92222 14.6666 7.99999 14.6666Z"
        fill="#DA1E28"
      />
    </svg>
  )
}
