import * as yup from 'yup'
import {
  validationInvalidFormatKey,
  validationMinLengthKey,
  validationRequiredKey,
} from 'src/shared/constants'

export const cardNumberValidationSchema = yup
  .string()
  .transform((value: string) => value.replaceAll(' ', ''))
  .required(validationRequiredKey)
  .min(13, validationMinLengthKey)
  .matches(
    /^(4[0-9]{12,18}|5[1-5][0-9]{11,17}|2[0-9]{12,18}$)$/,
    validationInvalidFormatKey,
  )
