import * as yup from 'yup'
import {
  validationInvalidFormatKey,
  validationMaxLengthKey,
  validationRequiredKey,
} from 'src/shared/constants'

export const phoneValidationSchema = yup
  .string()
  .required(validationRequiredKey)
  .max(19, validationMaxLengthKey)
  .max(19, validationMaxLengthKey)
  .matches(
    /^\+?(\d{1,3})?[-. ]?(\(\d{1,3}\))?[-. ]?\d{1,4}[-. ]?\d{1,4}[-. ]?\d{1,9}$/,
    validationInvalidFormatKey,
  )
