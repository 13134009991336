import { FC } from 'react'
// local libs
import { PaymentMethodCard } from 'src/shared/ui'
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { SavedCards } from 'src/shared/ui/SavedCards/SavedCards'
import { store } from 'src/app/store'
// types
import {
  MethodNameEnum,
  PaymentMethodProps,
} from 'src/app/Layout/PaymentMethods/types'
import { RegularForm, SavedCardForm } from './forms'

export const BankCardMethod: FC<PaymentMethodProps> = ({ disabled }) => {
  const { translate } = useTranslate()

  return (
    <PaymentMethodCard
      id={MethodNameEnum.Bankcard}
      title={translate('Bank Card Method.title')}
      caption={translate('Bank Card Method.caption')}
      iconLink="/payment-card-fallback-icon.svg"
      iconWrapperColor="var(--paymentMethodCard-cardMethodColor)"
      disabled={disabled}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <SavedCards />
      </div>

      {store.selectedSavedCard.value ? <SavedCardForm /> : <RegularForm />}
    </PaymentMethodCard>
  )
}
