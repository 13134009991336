import { FC, SVGProps } from 'react'

export const CrossedEyeIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="16"
        height="16"
        fill="white"
        fillOpacity="0.01"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        d="M2.61997 11.255L3.33497 10.545C2.55208 9.84206 1.93696 8.97234 1.53497 8C2.54997 5.465 5.34997 3.5 7.99997 3.5C8.68192 3.509 9.35769 3.63064 9.99997 3.86L10.775 3.08C9.8963 2.70866 8.95382 2.51167 7.99997 2.5C6.37023 2.56129 4.79416 3.09878 3.46657 4.04604C2.13898 4.99331 1.11805 6.30882 0.52997 7.83C0.490254 7.93985 0.490254 8.06015 0.52997 8.17C0.974094 9.34851 1.69018 10.4055 2.61997 11.255Z"
        fill="currentColor"
      />
      <path
        d="M5.99997 7.865C6.03474 7.38586 6.24082 6.93524 6.58051 6.59554C6.92021 6.25584 7.37083 6.04977 7.84997 6.015L8.75497 5.105C8.24785 4.97147 7.71459 4.97321 7.20835 5.11003C6.70211 5.24685 6.24058 5.51399 5.86977 5.8848C5.49896 6.25561 5.23182 6.71714 5.095 7.22338C4.95818 7.72962 4.95644 8.26288 5.08997 8.77L5.99997 7.865Z"
        fill="currentColor"
      />
      <path
        d="M15.47 7.83C14.8966 6.3366 13.899 5.04351 12.6 4.11L15 1.705L14.295 1L0.99997 14.295L1.70497 15L4.25497 12.45C5.39191 13.117 6.68199 13.4787 7.99997 13.5C9.62971 13.4387 11.2058 12.9012 12.5334 11.954C13.861 11.0067 14.8819 9.69118 15.47 8.17C15.5097 8.06015 15.5097 7.93985 15.47 7.83ZM9.99997 8C9.99786 8.35005 9.90392 8.69342 9.72752 8.99578C9.55113 9.29815 9.29846 9.54892 8.99477 9.72303C8.69109 9.89714 8.34702 9.9885 7.99697 9.98797C7.64691 9.98744 7.30312 9.89503 6.99997 9.72L9.71997 7C9.89972 7.30287 9.99631 7.64783 9.99997 8ZM7.99997 12.5C6.951 12.4817 5.92192 12.2107 4.99997 11.71L6.26997 10.44C6.84764 10.8408 7.54774 11.026 8.24804 10.9632C8.94833 10.9005 9.60434 10.5937 10.1015 10.0965C10.5987 9.59937 10.9054 8.94336 10.9682 8.24307C11.031 7.54277 10.8458 6.84267 10.445 6.265L11.88 4.83C13.0273 5.61749 13.9244 6.71771 14.465 8C13.45 10.535 10.65 12.5 7.99997 12.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
