import { FC, useEffect, useState } from 'react'
import cn from 'classnames'
// local libs
import { TrashIcon } from 'src/shared/assets/icons'
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { Button, CardPaySystemIcon, Text } from 'src/shared/ui'
// styles
import styles from './SavedCards.module.scss'
// types
import { CardProps } from './SavedCards.types'

export const Card: FC<CardProps> = ({
  id,
  cardType,
  lastDigits,
  isSelected,
  handleSelect,
  handleRemove,
}) => {
  const { translate } = useTranslate()
  const [confirm, setConfirm] = useState(false)

  useEffect(() => {
    !isSelected && setConfirm(false)
  }, [isSelected])

  return (
    <div
      onClick={() => handleSelect(id)}
      role="listitem"
      className={cn(styles.cardContainer, {
        [styles.cardActive]: isSelected,
        [styles.cardDanger]: confirm,
        [styles.cardHeight]: !confirm,
      })}
      aria-label="saved card"
      aria-selected={isSelected}
    >
      <div className={styles.card}>
        <label className={styles.checkboxContainer}>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => {}}
            aria-checked={isSelected}
            aria-label="select card checkbox"
          />
          <span
            className={styles.checkmark}
            onMouseDown={() => handleSelect(id)}
          />
        </label>

        <CardPaySystemIcon cardType={cardType} />

        <Text className={styles.lastDigits}>
          {'•••• '}
          {lastDigits}
        </Text>

        <button
          className={cn(styles.removeBtn, {
            [styles.removeBtnActive]: confirm,
          })}
          aria-label="remove saved card"
          onClick={(e) => {
            e.stopPropagation()
            setConfirm(true)
          }}
          aria-controls="confirmation-dialog"
        >
          <TrashIcon />
        </button>
      </div>

      {confirm && (
        <div id="confirmation-dialog" className={styles.confirmationDialog}>
          <Text className={styles.confirmationText}>
            {translate('Bank Card Method.deleteSavedCardConfirmText')}
          </Text>
          <div className={styles.buttonContainer}>
            <Button
              kind="secondary"
              size="stretch"
              onClick={() => setConfirm(false)}
              aria-label="cancel deletion"
            >
              {translate('Bank Card Method.deleteSavedCardCancelButton')}
            </Button>
            <Button
              kind="danger"
              size="stretch"
              onClick={() => handleRemove(id)}
              aria-label="delete saved card"
            >
              {translate('Bank Card Method.deleteSavedCardDeleteButton')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
