import { useEffect, useState } from 'react'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useNavigate } from 'react-router-dom'
// local libs
import { extendAxiosHeaders } from 'src/shared/utils'
import { initI18n } from '../config/i18next'
import { store, storeActions } from '../store'
import { userSession } from '../config/api'

export const useAppInit = () => {
  const navigate = useNavigate()

  const [isI18nInitiated, setIsI18nInitiated] = useState(false)
  const [isTerminalDataLoaded, setIsTerminalDataLoaded] = useState(false)

  const makeFingerprint = async () => {
    const fp = await FingerprintJS.load()
    const { visitorId: fingerprint } = await fp.get()
    extendAxiosHeaders({ Fingerprint: fingerprint })
  }

  const initInternationalization = async () => {
    await initI18n()
    setIsI18nInitiated(true)
  }

  const requestTerminalData = async () => {
    await storeActions.collectTerminalData()
    setIsTerminalDataLoaded(true)
    store.isLoading.value = false
  }

  useEffect(() => {
    const initApp = async () => {
      await makeFingerprint()
      initInternationalization()
      requestTerminalData()
    }
    initApp()
  }, [])

  const isAppInitiated = isI18nInitiated && isTerminalDataLoaded

  useEffect(() => {
    if (isAppInitiated && store.shouldNavigate.value) {
      navigate('/contact-store')
    }
    if (userSession && isAppInitiated && !store.shouldNavigate.value) {
      navigate(`/open/${userSession}`)
    }
  }, [isAppInitiated, store.shouldNavigate.value])

  return { isAppInitiated }
}
