import { store } from '../store'
import { requestPaymentData } from './requestPaymentData'
import { requestPaymentMethods } from './requestPaymentMethods'

export const collectTerminalData = async () => {
  try {
    const { paymentInfo } = await requestPaymentData()
    await requestPaymentMethods(paymentInfo)
    return Promise.resolve()
  } catch (err) {
    store.hasError.value = true
    store.isLoading.value = false
    return
  }
}
