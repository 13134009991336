import { FC } from 'react'

export const TrashIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0H4V1H8V0ZM0 2V3H1V13C1 13.6 1.4 14 2 14H10C10.6 14 11 13.6 11 13V3H12V2H0ZM2 13V3H10V13H2ZM4 5H5V11H4V5ZM8 5H7V11H8V5Z"
      />
    </svg>
  )
}
