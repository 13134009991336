import { FC } from 'react'
import { CopyIcon } from 'src/shared/assets/icons'
// local libs
import { Button } from 'src/shared/ui'
// types
import { CopyButtonProps } from './types'

export const CopyButton: FC<CopyButtonProps> = ({
  text,
  copyText,
  textStyles,
  ...rest
}) => {
  const handleCopyText = () => navigator.clipboard.writeText(copyText)

  return (
    <Button
      {...rest}
      kind="tertiary"
      icon={<CopyIcon />}
      onClick={handleCopyText}
    >
      <span className={textStyles}>{text + ' ' + copyText}</span>
    </Button>
  )
}
