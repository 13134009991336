import { ResponseStatusEnum } from 'src/shared/types/enums'
import { ValidationErrorMessages } from '../types'

export const unexpectedError: ResponseMessage = {
  type: ResponseStatusEnum.ERROR,
  localKey: 'UI..Notification.errorLoadingData',
}

type ResponseMessages = ResponseMessage | ValidationErrorMessages

export const isResponseMessage = (
  x: ResponseMessages | unknown,
): x is ResponseMessages => Boolean(x && typeof x === 'object' && 'type' in x)
