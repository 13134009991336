import axios, { AxiosError } from 'axios'
// local libs
import { api, userSession } from 'src/app/config/api'
import { store } from '../store'
// types
import { DataContextValues, PaymentData } from '../store.types'

type Data = DataContextValues['paymentMethods']
type Response = ResponseWithData<Data>
type PaymentInfo = PaymentData['paymentInfo']

export const requestPaymentMethods = async (paymentInfo: PaymentInfo) => {
  const { amount, currency } = paymentInfo

  return axios
    .get<Response>(api.paymentMethods, {
      params: { amount, currency, hash: userSession },
    })
    .then((res) => {
      const resData = res.data

      if (resData.data) {
        store.paymentMethods.value = resData.data

        return Promise.resolve()
      }

      throw Error('No data')
    })
    .catch((err: AxiosError<ResponseMessage>) => {
      console.error("Can't load payment methods:", err)

      return Promise.reject()
    })
}
