import { Routes, Route } from 'react-router-dom'
// local libs
// import { BankCardMethod } from 'src/paymentMethods/BankCardMethod';
import { PaymentMethods } from '../Layout/PaymentMethods'
import { ErrorDisplay, ContactPageRedirect } from 'src/shared/ui'
import { userSession } from '../config/api'

const contactPage = <ErrorDisplay variant="contact" />

export const AppRoutes = () => {
  return (
    <Routes>
      {userSession && (
        <Route
          path="/open/:hash"
          element={<PaymentMethods />}
          errorElement={contactPage}
        />
      )}

      <Route
        path="/payment-success"
        element={<ErrorDisplay variant="completed" />}
      />
      <Route path="/payment-error" element={<ErrorDisplay variant="error" />} />
      <Route path="/contact-store" element={contactPage} />

      <Route path="*" element={<ContactPageRedirect />} />
    </Routes>
  )
}
