export enum BankCardFormEntities {
  cardNumber = 'cardNumber',
  expDate = 'expDate',
  cvc = 'cvc',
  cardHolderName = 'cardHolderName',
  saveData = 'saveData',
  sendReceipt = 'sendReceipt',
  email = 'email',
}

export type BankCardFromData = {
  [BankCardFormEntities.cardNumber]: string
  [BankCardFormEntities.expDate]: string
  [BankCardFormEntities.cvc]: string
  [BankCardFormEntities.cardHolderName]: string
  [BankCardFormEntities.email]: string
  [BankCardFormEntities.saveData]?: boolean
  [BankCardFormEntities.sendReceipt]?: boolean
}
