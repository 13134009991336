// types
import type {
  ValidationErrorMessages,
  ValidationErrorParameter,
} from '../types'

export const isValidationErrorMessages = (
  x: ValidationErrorMessages | unknown,
): x is ValidationErrorMessages =>
  Boolean(x && typeof x === 'object' && ('errors' in x || 'fields' in x))

export const getMessageParams = (
  parameters: Array<ValidationErrorParameter> | Array<Parameter> | undefined,
  translate: (key: string) => string,
) => {
  let allParams: Record<string, string> = {}

  if (parameters) {
    parameters.forEach((param) => {
      if ('values' in param && param.values) {
        allParams = {
          ...allParams,
          [param.name]: Array.isArray(param.values)
            ? param.values.join(' ')
            : param.values,
        }
      } else if ('value' in param && param.value) {
        allParams = { ...allParams, [param.name]: param.value }
      } else if (param.localKey) {
        allParams = { ...allParams, [param.name]: translate(param.localKey) }
      }
    })
  }

  return allParams
}
