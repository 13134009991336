import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const ContactPageRedirect = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/contact-store')
  }, [])

  return <></>
}
