import { FC, SVGProps } from 'react'

export const EyeIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.4698 5.83C14.8817 4.30882 13.8608 2.99331 12.5332 2.04604C11.2056 1.09878 9.62953 0.561286 7.99979 0.5C6.37005 0.561286 4.79398 1.09878 3.46639 2.04604C2.1388 2.99331 1.11787 4.30882 0.529787 5.83C0.490071 5.93985 0.490071 6.06015 0.529787 6.17C1.11787 7.69118 2.1388 9.00669 3.46639 9.95396C4.79398 10.9012 6.37005 11.4387 7.99979 11.5C9.62953 11.4387 11.2056 10.9012 12.5332 9.95396C13.8608 9.00669 14.8817 7.69118 15.4698 6.17C15.5095 6.06015 15.5095 5.93985 15.4698 5.83ZM7.99979 10.5C5.34979 10.5 2.54979 8.535 1.53479 6C2.54979 3.465 5.34979 1.5 7.99979 1.5C10.6498 1.5 13.4498 3.465 14.4648 6C13.4498 8.535 10.6498 10.5 7.99979 10.5Z"
        fill="currentColor"
      />
      <path
        d="M7.99979 3C7.40644 3 6.82642 3.17595 6.33308 3.50559C5.83973 3.83524 5.45521 4.30377 5.22815 4.85195C5.00109 5.40013 4.94168 6.00333 5.05743 6.58527C5.17319 7.16721 5.45891 7.70176 5.87847 8.12132C6.29802 8.54088 6.83257 8.8266 7.41452 8.94236C7.99646 9.05811 8.59966 8.9987 9.14784 8.77164C9.69602 8.54458 10.1646 8.16006 10.4942 7.66671C10.8238 7.17336 10.9998 6.59334 10.9998 6C10.9998 5.20435 10.6837 4.44129 10.1211 3.87868C9.5585 3.31607 8.79544 3 7.99979 3ZM7.99979 8C7.60422 8 7.21755 7.8827 6.88865 7.66294C6.55975 7.44318 6.3034 7.13082 6.15203 6.76537C6.00065 6.39991 5.96105 5.99778 6.03822 5.60982C6.11539 5.22186 6.30587 4.86549 6.58557 4.58579C6.86528 4.30608 7.22164 4.1156 7.60961 4.03843C7.99757 3.96126 8.3997 4.00087 8.76515 4.15224C9.13061 4.30362 9.44296 4.55996 9.66273 4.88886C9.88249 5.21776 9.99979 5.60444 9.99979 6C9.99979 6.53043 9.78907 7.03914 9.414 7.41421C9.03893 7.78929 8.53022 8 7.99979 8Z"
        fill="currentColor"
      />
    </svg>
  )
}
