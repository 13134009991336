import { FC } from 'react'

export const CopyIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_875_13178)">
        <path
          d="M14.8571 4.57143V14.8571H4.57143V4.57143H14.8571ZM14.8571 3.42857H4.57143C4.26832 3.42857 3.97763 3.54898 3.76331 3.76331C3.54898 3.97763 3.42857 4.26832 3.42857 4.57143V14.8571C3.42857 15.1602 3.54898 15.4509 3.76331 15.6653C3.97763 15.8796 4.26832 16 4.57143 16H14.8571C15.1602 16 15.4509 15.8796 15.6653 15.6653C15.8796 15.4509 16 15.1602 16 14.8571V4.57143C16 4.26832 15.8796 3.97763 15.6653 3.76331C15.4509 3.54898 15.1602 3.42857 14.8571 3.42857Z"
          fill="currentColor"
        />
        <path
          d="M1.14286 9.14286H0V1.14286C0 0.839753 0.120408 0.549062 0.334735 0.334735C0.549062 0.120408 0.839753 0 1.14286 0H9.14286V1.14286H1.14286V9.14286Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_875_13178">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
