import { FC } from 'react'
// local libs
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { Input } from '../../Input'
// types
import { CardNumberInputProps } from './types'

export const CardNumberInput: FC<CardNumberInputProps> = ({
  id,
  label,
  placeholder,
  control,
  error,
}) => {
  const { translate } = useTranslate()

  return (
    <Input
      name={id}
      id={id}
      control={control}
      error={translate(error)}
      label={label}
      autoComplete="cc-number"
      placeholder={placeholder}
      mask="____ ____ ____ ____"
      replacement={{ _: /\d/ }}
    />
  )
}
