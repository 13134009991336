import { FC } from 'react'
// local libs
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { Input } from '../../Input'
// types
import { EmailInputProps } from './types'

export const EmailInput: FC<EmailInputProps> = ({
  id,
  label,
  placeholder,
  control,
  error,
}) => {
  const { translate } = useTranslate()

  return (
    <Input
      name={id}
      id={id}
      control={control}
      error={translate(error)}
      label={label}
      autoComplete="email"
      placeholder={placeholder}
    />
  )
}
