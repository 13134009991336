import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import axios, { AxiosError } from 'axios'
import cn from 'classnames'
// local
import { api, userSession } from 'src/app/config/api'
import { store } from 'src/app/store'
import { ResponseStatusEnum } from 'src/shared/types/enums'
import { savedCardResolver } from '../resolver'
import { CardCodeInput } from 'src/shared/ui/Input'
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { Button } from 'src/shared/ui'
import { CARD_METHODS } from 'src/shared/constants/cardMethods'
// types
import { BankCardFormEntities, BankCardFromData } from '../types'

import styles from '../BankCardMethod.module.scss'

export const SavedCardForm: FC = () => {
  const navigate = useNavigate()
  const { translate } = useTranslate()

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Pick<BankCardFromData, BankCardFormEntities.cvc>>({
    resolver: savedCardResolver,
  })

  const onSubmit = (
    formVals: Pick<BankCardFromData, BankCardFormEntities.cvc>,
  ) =>
    store.selectedSavedCard.value &&
    axios
      .request<
        ResponseWithData<{
          amount: number
          currency: string
          transactionId: string
        }>
      >({
        url: api.deposit,
        method: 'POST',
        data: {
          cardInfo: {
            id: store.selectedSavedCard.value?.id,
            cvv: formVals[BankCardFormEntities.cvc],
          },
          paymentMethod: CARD_METHODS.bankcard,
          hash: userSession,
        },
      })
      .then((res) => {
        if (res.data.data && res.data.type === ResponseStatusEnum.DATA) {
          return navigate('/payment-success')
        }
        navigate('/contact-store')
      })
      .catch((err: AxiosError<ResponseMessage>) => {
        console.error('Deposit error:', err)
        navigate('/contact-store')
        return Promise.reject()
      })

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.bankCardMethodContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={cn(styles.row, styles.w50)}>
        <CardCodeInput
          id={BankCardFormEntities.cvc}
          control={control}
          error={errors[BankCardFormEntities.cvc]?.message}
          label={translate('Bank Card Method.fieldCVCLabel')}
          placeholder={translate('Bank Card Method.fieldCVCPlaceholder')}
        />
      </div>
      <div className={styles.row}></div>
      <Button size="stretch" disabled={isSubmitting}>
        {translate('Bank Card Method.submitButton', {
          amount: `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`,
        })}
      </Button>
    </form>
  )
}
