import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
// local libs
import {
  CheckboxCheckedIcon,
  CheckboxIndefiniteIcon,
} from 'src/shared/assets/icons'
import { Text } from '../Text'
// styles
import styles from './Checkbox.module.scss'
// types
import { TextKind, TextSizes } from '../Text/types'
import type { CheckboxProps } from './Checkbox.types'

export const Checkbox: FC<CheckboxProps> = ({
  id,
  label,
  control,
  setValue,
  ...rest
}) => {
  const handleKeyPress =
    (value: boolean) =>
    (e: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
      if (e.key === 'Enter' || e.key === ' ') {
        setValue && setValue(!value)
      }
    }

  return (
    <Controller
      name={id}
      control={control as Control<FieldValues, unknown>}
      render={({ field }) => (
        <div className={styles.checkbox}>
          <label>
            <div
              className={styles.checkboxContainer}
              tabIndex={0}
              onKeyDown={handleKeyPress(field.value)}
            >
              <input {...rest} {...field} type="checkbox" />

              {field.value ? (
                <CheckboxCheckedIcon className={styles.checkboxIcon} />
              ) : (
                <CheckboxIndefiniteIcon className={styles.checkboxIcon} />
              )}
            </div>

            <Text kind={TextKind.label} size={TextSizes.medium}>
              {label}
            </Text>
          </label>
        </div>
      )}
    />
  )
}
