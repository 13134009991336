import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
// local libs
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { Button, PaymentMethodCard, Text } from 'src/shared/ui'
import { TextKind } from 'src/shared/ui/Text'
import { PhoneInput } from 'src/shared/ui/Input/specificInputs/PhoneInput'
import { store } from 'src/app/store'
import { SmsCodeInput } from 'src/shared/ui/Input/specificInputs/SmsCodeInput'
import {
  mobileCommercePhoneResolver,
  mobileCommerceSmsCodeResolver,
} from './resolver'
import { paymentRequestSuccessMock } from 'src/shared/assets/mocks'
// styles
import styles from './MobileCommerceMethod.module.scss'
// types
import {
  MethodNameEnum,
  PaymentMethodProps,
} from 'src/app/Layout/PaymentMethods/types'
import {
  MobileCommerceEntities,
  MobileCommerceFromDataPhoneStep,
  MobileCommerceFromDataSmsCodeStep,
} from './types'

export const MobileCommerceMethod: FC<PaymentMethodProps> = ({ disabled }) => {
  const { translate } = useTranslate()
  const [currStep, setCurrStep] = useState<MobileCommerceEntities>(
    MobileCommerceEntities.phone,
  )
  const navigate = useNavigate()

  const {
    handleSubmit: handlePhoneStepSubmit,
    control: phoneStepControl,
    formState: { errors: phoneStepErrors },
  } = useForm<MobileCommerceFromDataPhoneStep>({
    resolver: mobileCommercePhoneResolver,
  })

  const {
    handleSubmit: handleSmsCodeStepSubmit,
    control: smsCodeStepControl,
    formState: { errors: smsCodeStepErrors, isSubmitting },
  } = useForm<MobileCommerceFromDataSmsCodeStep>({
    resolver: mobileCommerceSmsCodeResolver,
  })

  const currStepForm = {
    [MobileCommerceEntities.phone]: (
      <form
        onSubmit={handlePhoneStepSubmit(() => {
          // eslint-disable-next-line no-console
          store.isPaymentMethodsDisabled.value = true
          setCurrStep(MobileCommerceEntities.smsCode)
        })}
        onClick={(e) => e.stopPropagation()}
        className={styles.form}
      >
        <Text kind={TextKind.title} className={styles.title}>
          {translate('Mobile Commerce Method.subtitlePhone')}
        </Text>
        <PhoneInput
          id={MobileCommerceEntities.phone}
          control={phoneStepControl}
          label={translate('Mobile Commerce Method.fieldPhoneLabel')}
          placeholder={translate(
            'Mobile Commerce Method.fieldPhonePlaceholder',
          )}
          error={phoneStepErrors[MobileCommerceEntities.phone]?.message}
        />
        <Button size="stretch">
          {translate('Mobile Commerce Method.submitButton', {
            amount: `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`,
          })}
        </Button>
      </form>
    ),
    [MobileCommerceEntities.smsCode]: (
      <form
        onSubmit={handleSmsCodeStepSubmit(async () => {
          //TODO: мок
          await paymentRequestSuccessMock()
          navigate('/payment-success')
        })}
        onClick={(e) => e.stopPropagation()}
        className={styles.form}
      >
        <Text kind={TextKind.title} className={styles.title}>
          {translate('Mobile Commerce Method.subtitleSms')}
        </Text>
        <SmsCodeInput
          id={MobileCommerceEntities.smsCode}
          control={smsCodeStepControl}
          label={translate('Mobile Commerce Method.fieldSmsLabel')}
          placeholder={translate('Mobile Commerce Method.fieldSmsPlaceholder')}
          error={smsCodeStepErrors[MobileCommerceEntities.smsCode]?.message}
        />
        <Button size="stretch" disabled={isSubmitting}>
          {translate('Mobile Commerce Method.submitButton', {
            amount: `${store.paymentData.value?.paymentInfo.amount} ${store.paymentData.value?.paymentInfo.currency}`,
          })}
        </Button>
      </form>
    ),
  }

  return (
    <PaymentMethodCard
      id={MethodNameEnum.MobileCommerce}
      title={translate('Mobile Commerce Method.title')}
      caption={translate('Mobile Commerce Method.caption')}
      iconLink="/mobile-commerce.svg"
      iconWrapperColor="var(--paymentMethodCard-mobileCommerceMethodColor)"
      disabled={disabled}
    >
      {currStepForm[currStep]}
    </PaymentMethodCard>
  )
}
