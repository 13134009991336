import { FC } from 'react'

export const McIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="26"
      height="16"
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5076 1.71136H9.47156V14.2886H16.5076V1.71136Z"
        fill="#FF5A00"
      />
      <path
        d="M9.93994 8.00005C9.93994 5.44474 11.1473 3.17679 13 1.71143C11.6365 0.644777 9.91653 0.00012207 8.04043 0.00012207C3.59607 0.00012207 0 3.57809 0 8.00005C0 12.422 3.59607 16 8.04043 16C9.91653 16 11.6365 15.3553 13 14.2887C11.1447 12.844 9.93994 10.5554 9.93994 8.00005Z"
        fill="#EB001B"
      />
      <path
        d="M26 7.99993C26 12.4219 22.4039 15.9999 17.9596 15.9999C16.0835 15.9999 14.3635 15.3552 13 14.2886C14.8761 12.8206 16.06 10.5553 16.06 7.99993C16.06 5.44461 14.8527 3.17667 13 1.71131C14.3609 0.644655 16.0809 0 17.957 0C22.4039 0 26 3.60126 26 7.99993Z"
        fill="#F79E1B"
      />
    </svg>
  )
}
