import { FC, useRef, useState } from 'react'
import axios, { AxiosError } from 'axios'
// local libs
import { useSaveCardsRequest } from 'src/shared/hooks/useSavedCardsRequest'
import { useResponseHandler } from 'src/shared/hooks/useResponseHandler'
import { api, userSession } from 'src/app/config/api'
import { Card } from './Card'
import { SavedCardProps } from './SavedCards.types'
import { store } from 'src/app/store'
import { parseCardType } from 'src/shared/utils'
import styles from './SavedCards.module.scss'

export const SavedCards: FC<SavedCardProps> = ({ onCardSelect }) => {
  const { cards, refresh } = useSaveCardsRequest()
  const { handleResponse } = useResponseHandler()
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null)

  const handleCardSelect = (id: string | null = null) => {
    if (id === selectedCardId) {
      setSelectedCardId(null)
      store.selectedSavedCard.value = null
      onCardSelect?.(null)
      return
    }
    setSelectedCardId(id)
    const selectedCard = cards.find((v) => v.id === id) || null
    store.selectedSavedCard.value = selectedCard
    onCardSelect?.(selectedCard)
  }

  const handleRemove = (id: string) => {
    axios
      .request<ResponseMessage>({
        method: 'DELETE',
        url: `${api.cards}/${id}`,
        data: {
          id,
          hash: userSession,
        },
      })
      .then((res) => handleResponse(res.data))
      .catch((err: AxiosError<ResponseMessage>) => {
        const errData = err.response?.data
        if (errData) handleResponse(errData)
      })
      .finally(() => {
        handleCardSelect(null)
        onCardSelect?.(null)
        refresh()
      })
  }

  const containerRef = useRef(null)

  return cards?.length ? (
    <div
      className={styles.cardList}
      role="list"
      aria-label="saved card list"
      ref={containerRef}
    >
      {cards.map(({ id, lastChars, systemCode }) => (
        <Card
          key={id}
          id={id}
          cardType={parseCardType(systemCode)}
          lastDigits={lastChars}
          isSelected={id === selectedCardId}
          handleRemove={handleRemove}
          handleSelect={handleCardSelect}
        />
      ))}
    </div>
  ) : null
}
