import * as yup from 'yup'
import {
  validationInvalidFormatKey,
  validationMinLengthKey,
  validationRequiredKey,
} from 'src/shared/constants'

export const cardCodeValidationSchema = yup
  .string()
  .required(validationRequiredKey)
  .min(3, validationMinLengthKey)
  .matches(/^(?!00)\d/, validationInvalidFormatKey)
